<template>
  <div class="m-5 p-3">
    <el-row>
      <el-col :md="24" class="pr-4">
        <div>
          <h2 class="pb-2">{{ $t("PrivateInperson.title") }}</h2>
          <div v-html="$t('PrivateInperson.desc1')"></div>
          <div v-html="$t('PrivateInperson.desc2')"></div>
        </div>
      </el-col>
    </el-row>
    <div class="pt-5">
      <h4 style="font-weight:bold;">{{ $t("PrivateInperson.how_works") }}</h4>
      <ul>
        <li>{{ $t("PrivateInperson.desc_works") }}</li>
      </ul>
      <el-image
        src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/tutoring_flow_chart_en.png"
        title="tutoring flow chart"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.img {
  padding-left: 20px;
}

@media (max-width: 992px) {
  .img {
    padding-top: 20px;
    padding-left: 20px;
  }
}
</style>
